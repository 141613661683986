import React, {useContext, useEffect, useState} from "react";
import ZenButton from "../../../../../../components/ZenButton";
import {EAlertType, GlobalAlertContext} from "../../../../../../components/GlobalAlert";
import ApiClient from "../../../../../../lib/ApiClient";
// @ts-ignore
import RobotGif from "../../../../../../../assets/img/robot.gif";
import GoalSuggestionCard from "../../../../../AnalyseGoal/GoalSuggestionsSection/GoalSuggestionCard";
import {
    EActiveStatus,
    ETaskPriority,
    ETaskStatus,
    EZenObjectiveSuggestionStatus,
    IZenObjectiveSuggestion,
    IZenObjectiveSuggestionModel,
    IZenTask
} from "@yellowmelon/zen-global-types";

interface Props {
    task: IZenTask
    updateTask: (updatedTask: IZenTask) => void
}

const apiClient = new ApiClient();

const TaskSuggestionsSection = ({task, updateTask}: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<IZenObjectiveSuggestionModel[]>(task.suggestions);
    const {showAlert} = useContext(GlobalAlertContext)

    useEffect(
        () => {

            setSuggestions(task.suggestions);

        }, [task]
    )

    const getSuggestions = () => {

        setLoading(true);

        apiClient.put<{suggestions: IZenObjectiveSuggestion[]}>(`api/v1/tasks/analyse/suggestions/${task._id}`).then(
            (response: { suggestions: IZenObjectiveSuggestion[] }) => {

                setLoading(false);

                // Update task with new suggestions
                apiClient.put<IZenTask>(`api/v1/tasks/suggestions/${task._id}`,
                    {
                        payload:
                            {suggestions: response.suggestions}
                    }).then(
                    (updatedTask) => {

                        updateTask(updatedTask);

                    }
                ).catch(
                    (err: any) => {

                        console.error(`Sorry but an error occurred: ${err.message}`);
                        showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

                    }
                )


            }
        ).catch(
            (err) => {

                setLoading(false);
                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )


    }

    const deleteSuggestion = (suggestionId: string) => {

        if (!confirm('Delete suggestion? This action cannot be undone')) return;

        apiClient.delete<IZenTask>(`api/v1/tasks/suggestions/${task._id}/${suggestionId}`).then(
            (updatedTask: IZenTask) => {

                updateTask(updatedTask)

            }
        ).catch(
            (err: any) => {

                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const createTaskFromSuggestion = (suggestion: IZenObjectiveSuggestionModel) => {

        if(!confirm('This will create a new task in your kanban board from this suggestion. Proceed?')) return;

        const newTask: IZenTask = {
            title: suggestion.method,
            description: suggestion.description,
            priority: ETaskPriority.normal,
            status: ETaskStatus.inbox,
            context: task?.context ? task.context : null,
            checklist: [],
            index: 0,
            suggestions: [],
            taskList: [],
            activeStatus: EActiveStatus.active,
            notes: [],
            resources: []
        }

        apiClient.post<IZenTask>(`api/v1/tasks`,
            {
                payload: {task: newTask}
            }
        ).then(
            async (createdTask: IZenTask)=>{

                const updatedSuggestion:IZenObjectiveSuggestionModel = {...suggestion, status: EZenObjectiveSuggestionStatus.deleted}

                // Keep a list of child tasks in the parent task
                await apiClient.put<IZenTask>(`api/v1/tasks/${task?._id}`,
                    {payload: { task : {...task, childTasks: [...task?.childTasks ? task.childTasks : [], createdTask._id] } as IZenTask} } )

                // Now update the status of the suggestion
                const updatedTask: IZenTask = await apiClient.put<IZenTask>(`api/v1/tasks/suggestions/${task?._id}/${suggestion._id}`,
                    {payload: {suggestion: {...updatedSuggestion, taskCreated: true}}})

                updateTask(updatedTask);

                showAlert(
                    EAlertType.info,
                    `This suggestion has been turned into a task which is now available to be managed in your kanban board`
                )

            }
        ).catch(
            (err: any) => {

                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    return (
        <div className={'suggestions-accordion-section'}>

            <div className={'suggestions-container'}>

                <div className={'mb-4'}>

                    <p className={'px-1 text-sm mb-4'}>
                        Use the magic of artificial intelligence to suggest ways to accomplish
                        your goals. Then save suggestions, manage them, turn them into new tasks.
                    </p>

                    <ZenButton
                        label={`Give me ${suggestions.length > 0 ? 'more ' : ''}suggestions`}
                        zenType={'primary'}
                        onClick={getSuggestions}/>

                    {
                        loading &&
                        <>
                            <div className='flex align-middle justify-center pt-1'>
                                <img className='w-[100]' src={RobotGif}/>
                            </div>
                            <label className='font-bold m-1'>Working on it...</label>
                        </>
                    }

                </div>

                <div className='task-container'>

                    {!!suggestions.length &&
                        <>
                            <div className={'text-sm py-2'}>
                                Here are {suggestions.length} suggestions to achieve your goal: <span
                                className={'font-bold'}>{task.title}</span>
                            </div>

                        </>
                    }

                    <div className={'py-4'}>

                        <ul className='max-h-[30rem] overflow-y-auto'>
                            {
                                suggestions.map(
                                    (suggestion: IZenObjectiveSuggestionModel) =>
                                        (<li key={suggestion.method}>
                                            <GoalSuggestionCard
                                                item={suggestion}
                                                createTask={
                                                    () => {
                                                        createTaskFromSuggestion(suggestion)
                                                    }
                                                }
                                                deleteItem={
                                                    () => {
                                                        suggestion?._id && deleteSuggestion(suggestion._id.toString())
                                                    }
                                                }
                                            />
                                        </li>)
                                )
                            }
                        </ul>

                    </div>


                </div>

            </div>

        </div>
    )

}

export default TaskSuggestionsSection
