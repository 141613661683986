import React from "react";
import ZenButton from "../../../../components/ZenButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

interface Props {
    className?: string
    filterPriority: boolean
    doFilterPriority: (filterPriority: boolean) => void
}

const FilterKanbanTasks = ({className = '', filterPriority, doFilterPriority}:Props) => {
    return (
        <div className={`filter-kanban-tasks ${!!className && className}`}>
            <ZenButton
                label={
                    <span className="flex items-center">
                        {filterPriority && <FontAwesomeIcon icon={faCheck} className="mr-2" />}
                        {filterPriority ? 'Showing Priority Tasks' : 'Show Priority Tasks'}
                    </span>
                }
                zenType="danger"
                size='sm'
                onClick={() => { doFilterPriority(!filterPriority) }}
                className={`
                    transition-all duration-300
                    ${filterPriority ? 'bg-red-400 hover:bg-red-500' : 'bg-red-600 hover:bg-red-700'}
                `}
            />
        </div>
    )
}

export default FilterKanbanTasks
