import React, {createContext, ReactNode, useState} from "react";
import './global-alert.less';

export enum EAlertType {
    info,
    success,
    warn,
    danger,
    dark
}


const styleMapping: { [key in EAlertType]: string } = {
    [EAlertType.info]: 'bg-blue-400 text-sm text-white rounded-md p-4 shadow',
    [EAlertType.success]: 'bg-green-500 text-sm text-white rounded-md p-4 shadow',
    [EAlertType.warn]: 'bg-orange-500 text-sm text-white rounded-md p-4 shadow',
    [EAlertType.danger]: 'bg-red-500 text-sm text-white rounded-md p-4 shadow',
    [EAlertType.dark]: 'bg-gray-800 text-sm text-white rounded-md p-4 shadow dark:bg-white dark:text-gray-800'
}

interface IGlobalAlertButton {
    label: string,
    callback?: () => void | Promise<void>
}

export interface IGlobalAlertFunction {
    (alertType: EAlertType,
     message: string,
     btnDismiss?: IGlobalAlertButton): void;
}

export interface IAlertContext {
    showAlert: IGlobalAlertFunction
}

export const GlobalAlertContext = createContext<IAlertContext>(
    {
        showAlert: ((alertType, message, btnDismiss) => {
        })
    }
)

const GlobalAlert = ({children}: { children: ReactNode }) => {

    const [alertShowing, setAlertShowing] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('this is the message')
    const [alertType, setAlertType] = useState<EAlertType>(EAlertType.info);
    const [dismissButton, setDismissButton] = useState<IGlobalAlertButton | undefined>()

    const showAlert: IGlobalAlertFunction = ((alertType, message, btnDismiss) => {

        setDismissButton(btnDismiss)

        setAlertType(alertType);
        setMessage(message);

        setAlertShowing(true);

        !btnDismiss && setTimeout(
            () => {
                setAlertShowing(false);
            }, 3000
        )

    })

    return (

        <GlobalAlertContext.Provider value={{showAlert}}>
            <>

                {children}

                {alertShowing && <div className={`global-alert ${styleMapping[alertType]}`} role="alert">

                    <span>{message}</span>

                    {
                        dismissButton &&
                        <button type='button'
                                className='ml-2 py-0.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800'
                                onClick={() => {

                                    if (dismissButton?.callback) {

                                        dismissButton.callback();
                                        setAlertShowing(false);

                                    } else {

                                        setAlertShowing(false);

                                    }

                                }}>
                            {dismissButton.label}
                        </button>
                    }

                </div>}

            </>

        </GlobalAlertContext.Provider>


    )

}

export default GlobalAlert
