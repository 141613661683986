import React from "react";
import ZenButton from "../../../../components/ZenButton";
import {
    EZenObjectiveSuggestionStatus,
    IZenObjectiveSuggestion,
    IZenObjectiveSuggestionModel,
    IZenTasklistItem
} from "@yellowmelon/zen-global-types";
import Tooltip from "../../../../components/Tooltip";
import ZenMiniAlert, {EMiniAlertType} from "../../../../components/ZenMiniAlert";

enum EItemType {
    suggestion,
    task
}

interface Props {
    item: IZenObjectiveSuggestionModel | IZenTasklistItem,
    createTask: (itemId: string) => void
    deleteItem: () => void
}

function isGoalSuggestion(item: IZenObjectiveSuggestion | IZenTasklistItem): item is IZenObjectiveSuggestionModel {
    return (item as IZenObjectiveSuggestion).method !== undefined;
}

const GoalSuggestionCard = ({item, createTask, deleteItem}: Props) => {

    let title: string;
    let itemType: EItemType = EItemType.suggestion;

    if (isGoalSuggestion(item)) {
        title = item.method;
    } else {
        title = `Step ${item.step.toString()}`;
        itemType = EItemType.task;
    }

    return (
        <div
            className='bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 cursor-pointer mb-2 p-4 pb-3'>
            <div className="text-left mb-2">

                <div className={'flex align-middle'}>

                    <h3 className="font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                        {title}
                    </h3>

                </div>

                <p className="text-sm text-gray-500">
                    {item.description}
                </p>

                <div className={'item-actions flex justify-end w-full pt-2'}>

                    <ZenButton label={'delete'} size={'xs'} zenType={'danger'} className={'mr-1'} onClick={() => {
                        deleteItem()
                    }}/>

                    {"status" in item && (item.status === EZenObjectiveSuggestionStatus.active) &&
                        < ZenButton
                            label={'create new task'}
                            size={'xs'}
                            onClick={() => {
                                item?._id && createTask(item._id.toString())
                            }}
                            zenType={'success'}
                            tooltip={<Tooltip iconClasses={'bg-black !py-[1px] !px-[6px]'}
                                              toolTip={'Create a new task from this suggestion'}/>}/>
                    }

                    {
                        "status" in item && (item.taskCreated) &&
                        <ZenMiniAlert className='cursor-default' message={'task created for this suggestion'} type={EMiniAlertType.info}/>
                    }


                </div>

            </div>
        </div>
    )

}

export default GoalSuggestionCard;
