import React, {useState} from 'react';
import {IZenNote} from '@yellowmelon/zen-global-types';
import ZenButton from '../ZenButton';
import {Editor} from "@tinymce/tinymce-react";
import * as DOMPurify from "dompurify";
import EditLinkModal from './EditLinkModal';
import EditAttachmentModal from './EditAttachmentModal';
import FileViewerModal from './FileViewerModal';

interface ZenNoteProps {
    note: IZenNote | null;
    isEditing: boolean;
    onUpdate: (note: IZenNote) => void;
}

interface FileViewerState {
    file: {
        url: string;
        caption: string;
        mimeType?: string;
    };
    index: number;
}

const ZenNote: React.FC<ZenNoteProps> = ({note, isEditing, onUpdate}) => {

    const [showEditLinkModal, setShowEditLinkModal] = useState(false);
    const [selectedLinkIndex, setSelectedLinkIndex] = useState<number | null>(null);
    const [showEditAttachmentModal, setShowEditAttachmentModal] = useState(false);
    const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState<number | null>(null);
    const [showFileViewer, setShowFileViewer] = useState(false);
    const [selectedFile, setSelectedFile] = useState<FileViewerState | null>(null);

    const handleNoteUpdate = (updates: Partial<IZenNote>) => {

        if (!note) return;

        const updatedNote = {
            ...note,
            ...updates
        };

        onUpdate(updatedNote);
    };


    const addLink = () => {

        setShowEditLinkModal(true);

    };

    const handleAddAttachment = () => {
        setSelectedAttachmentIndex(null);
        setShowEditAttachmentModal(true);
    };

    return (
        <div className='m-4'>
            <div>
                <div className="flex justify-between items-start mb-4">
                    {isEditing ? (
                        <label className="block w-full">
                            <span className="text-sm font-medium text-gray-700">Title</span>
                            <input
                                type="text"
                                placeholder={'Add a title for your note'}
                                value={note?.title || ''}
                                onChange={(e) => handleNoteUpdate({ title: e.target.value })}
                                className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                            />
                        </label>
                    ) : (
                        <h3 className="text-base font-semibold text-gray-800">{note?.title}</h3>
                    )}
                </div>

                <div className="mb-4 pt-4 border-t border-gray-100">
                    {isEditing ? (
                        <label className="block">
                            <span className="text-sm font-medium text-gray-700">Note</span>
                            <Editor
                                apiKey='egb518kqdaeb0sk0eujh61phf6jl6pdfg47cmnmmagn6s4t9'
                                value={note?.note || ''}
                                onEditorChange={
                                    (content: string) => {
                                        handleNoteUpdate({ note: content });
                                    }
                                }
                                init={{
                                    placeholder: 'Add description and comments for your note',
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </label>
                    ) : (
                        <div>
                            {!note?.note ? (
                                <span className="text-sm text-gray-500">No note content</span>
                            ) : (
                                <p
                                    className="text-gray-600 mb-2"
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(note.note || '')}}
                                />
                            )}
                        </div>
                    )}
                </div>

                <div className="mb-4 pt-4 border-t border-gray-100">
                    <h4 className="text-sm font-medium text-gray-700 mb-2 flex justify-between">
                        Links
                        <ZenButton
                            size='xs'
                            zenType="primary"
                            label="Add Link"
                            onClick={addLink}
                            disabled={!note?._id}
                            className="ml-auto self-end"
                        />
                    </h4>

                    <div className="flex justify-between items-center mb-2">
                        {note?.links.length === 0 && (
                            <span className="text-sm text-gray-500">No links</span>
                        )}
                    </div>

                    <div className="flex flex-wrap gap-2">
                        {note?.links?.map((link, index) => (
                            isEditing ? (
                                <div
                                    key={index}
                                    className="flex items-center bg-gray-100 rounded-md px-2 py-0.5 cursor-pointer"
                                    onClick={() => {
                                        setShowEditLinkModal(true);
                                        setSelectedLinkIndex(index);
                                    }}>
                                    <span className="text-sm text-gray-600">{link.url}</span>
                                </div>
                            ) : (
                                <a
                                    key={index}
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center text-sm bg-gray-100 rounded-md px-2 py-0.5 cursor-pointer">
                                    {link.caption || link.url}
                                </a>
                            )
                        ))}
                    </div>
                </div>

                <div className="mb-4 pt-4 border-t border-gray-100">
                    <h4 className="text-sm font-medium text-gray-700 mb-2 flex justify-between">
                        Attachments
                        <ZenButton
                            size='xs'
                            zenType="primary"
                            label="Add Attachment"
                            onClick={handleAddAttachment}
                            disabled={!note?._id}
                            className="ml-auto self-end"
                        />
                    </h4>

                    <div className="flex justify-between items-center mb-2">
                        {note?.attachments.length === 0 && (
                            <span className="text-sm text-gray-500">No attachments</span>
                        )}
                    </div>

                    <div className="flex flex-wrap gap-2">
                        {note?.attachments?.map((attachment, index) => (
                            isEditing ? (
                                <div
                                    key={index}
                                    className="flex items-center bg-gray-100 rounded-md px-2 py-0.5 cursor-pointer"
                                    onClick={() => {
                                        setSelectedFile({
                                            file: attachment,
                                            index: index
                                        });
                                        setShowFileViewer(true);
                                    }}
                                >
                                    <span className="text-sm text-gray-600">{attachment.caption}</span>
                                </div>
                            ) : (
                                <div 
                                    key={index} 
                                    className="flex flex-col items-center gap-1 cursor-pointer"
                                    onClick={() => {
                                        setSelectedFile({
                                            file: attachment,
                                            index: index
                                        });
                                        setShowFileViewer(true);
                                    }}
                                >
                                    {attachment.mimeType?.startsWith('image/') ? (
                                        <img 
                                            src={attachment.url} 
                                            alt={attachment.caption}
                                            className="w-24 h-24 object-cover rounded-md"
                                        />
                                    ) : (
                                        <div className="w-24 h-24 flex items-center justify-center bg-gray-100 rounded-md">
                                            <svg className="w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" />
                                            </svg>
                                        </div>
                                    )}
                                    <span className="text-xs text-gray-600 text-center truncate w-24" title={attachment.caption}>
                                        {attachment.caption.length > 20 
                                            ? `${attachment.caption.substring(0, 20)}...` 
                                            : attachment.caption}
                                    </span>
                                </div>
                            )
                        ))}
                    </div>
                </div>

                <div className="flex flex-wrap gap-2 mt-auto pt-4 border-t border-gray-100">
                    {note?.tags?.length === 0 && (
                        <span className="text-sm text-gray-500">No tags</span>
                    )}
                    {note?.tags?.map((tag, index) => (
                        <span
                            key={index}
                            className="px-2.5 py-0.5 text-xs font-medium text-gray-600 bg-gray-100 rounded-full"
                        >
                                    {tag.name}
                                </span>
                    ))}
                </div>

                <div className="mt-4 pt-4 border-t border-gray-100">
                    <div className="text-xs text-gray-500 mb-3">
                        {note?.updatedAt &&
                            <div>Updated: {new Date(note.updatedAt).toLocaleDateString()}</div>}
                    </div>

                </div>

            </div>

            {
                note && showEditLinkModal &&
                <EditLinkModal
                    note={note}
                    linkIndex={selectedLinkIndex}
                    updateNote={handleNoteUpdate}
                    closeModal={() => setShowEditLinkModal(false)}
                />
            }

            {
                note && showEditAttachmentModal &&
                <EditAttachmentModal
                    note={note}
                    attachmentIndex={selectedAttachmentIndex}
                    closeModal={() => {
                        setShowEditAttachmentModal(false);
                        setSelectedAttachmentIndex(null);
                    }}
                    updateNote={handleNoteUpdate}
                />
            }

            {showFileViewer && selectedFile && note?.attachments && (
                <FileViewerModal
                    file={selectedFile.file}
                    currentIndex={selectedFile.index}
                    attachments={note.attachments}
                    onClose={() => {
                        setShowFileViewer(false);
                        setSelectedFile(null);
                    }}
                    onNavigate={(index) => {
                        setSelectedFile({
                            file: note.attachments[index],
                            index: index
                        });
                    }}
                />
            )}

        </div>
    )
};

export default ZenNote;
