import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import ApiClient, {IApiRejectReason} from "../../../lib/ApiClient";
import {AuthContext} from "../../../components/Authenticate";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import {ManageBearerToken} from "../../../lib/ManageBearerToken";
import {useTranslation} from "react-i18next";
import {ELoginError, IUser} from "@yellowmelon/zen-global-types";

const apiClient = new ApiClient();

interface IFieldValidation {
    valid: boolean;
    dirty: boolean;
}

interface ILoginValidation {
    email: IFieldValidation;
    password: IFieldValidation;
}

interface ILoginForm {
    email: string;
    password: string
}

const tokenManager = new ManageBearerToken();

const Login = () => {

    // Add this line at the beginning of the component
    // @ts-ignore
    const grecaptcha = window.grecaptcha;

    const [loading, setLoading] = useState<boolean>(false);

    const [loginForm, setLoginForm] = useState<ILoginForm>({
        email: '',
        password: '',
    })

    const [validation, setValidation] = useState<ILoginValidation>(
        {
            email: {valid: true, dirty: false},
            password: {valid: true, dirty: false},
        }
    )

    const [formValid, setFormValid] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {showAlert} = useContext(GlobalAlertContext);
    const {doLogin} = useContext(AuthContext)
    const {t} = useTranslation();

    useEffect(
        () => {

            const keys: string[] = Object.keys(validation);
            let valid = true;

            keys.forEach(
                (key: string) => {

                    // @ts-ignore
                    if (!validation[key].valid) {
                        valid = false;
                    }

                }
            )

            setFormValid(valid);


        }, [validation]
    )

    const validateForm = () => {

        const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginForm.email);
        const passwordValid = !!loginForm.password?.length;

        setValidation({
            ...validation,
            email: {valid: emailValid, dirty: true},
            password: {valid: passwordValid, dirty: true}
        });


        return {
            emailValid, passwordValid
        }


    }

    const handleInput = (field: string, value: string | boolean) => {

        switch(field){  // Reset validation when user types

            case 'email':
                const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value as string);
                setValidation({...validation, email: { valid: emailValid, dirty: false }})
                break;

            case 'password':
                setValidation({...validation, password: { valid: true, dirty: false }})
                break;

        }

        setLoginForm({...loginForm, [field]: value})

    }

    const submit = async (ev: any) => {

        ev.preventDefault();

        const { emailValid, passwordValid } = validateForm();

        if(!emailValid || !passwordValid) return;

        setLoading(true);


        // Add reCAPTCHA token generation
        const googleToken = await new Promise<string>(
            (resolve, reject) => {
                grecaptcha.ready(function() {
                    grecaptcha.execute(
                        process.env.GOOGLE_RECAPTCHA_SITE_KEY,
                        {action: 'submit'}).then(
                        (token: string) => {
                            resolve(token);
                        });
                });
            }
        );

        apiClient.post<{ user: IUser, token: string }>(`auth/v1/login`, {
            payload: {
                user: {...loginForm},
                googleToken // Add the googleToken to the payload
            }
        }).then(
            ({user, token}) => {

                setLoading(false);
                tokenManager.setToken(token);
                doLogin && doLogin() // Auth manager will redirect

            }
        ).catch(
            (err: IApiRejectReason) => {

                console.error(err)
                let errorMessage = ''

                switch (err.reason) {

                    case ELoginError.USER_NOT_FOUND:

                        errorMessage = 'Sorry but this email address was not found. Please try a new email address or register a new one.'
                        break;

                    case ELoginError.PASSWORD_INCORRECT:
                        errorMessage = 'Sorry but this password is incorrect';
                        break;

                    default:

                        errorMessage = `An unexpected error has occurred: ${err?.message}`

                }

                setLoading(false);
                showAlert(EAlertType.danger, errorMessage, {label: 'Ok'})

            }
        )

    };

    const googleLogin = async (response: CredentialResponse) => {

        const credential = response.credential;

        if (!credential) {
            return showAlert(EAlertType.danger, 'Sorry but an error occurred: could not retrieve Google credential', {label: 'Ok'})
        }

        // Get user timezone from their browser
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {

            setLoading(true);

            const {token} = await apiClient.post<{
                user: IUser,
                token: string
            }>('auth/v1/googlelogin', {payload: {googleToken: credential, timezone}})

            setLoading(false);
            tokenManager.setToken(token);

            doLogin && doLogin() // Auth manager will redirect


        } catch (err: any) {

            showAlert(EAlertType.danger, `Sorry but an error occurred with this request: ${err.message}`, {label: 'Ok'})

        }

    }

    return (
        <div className='page-content register-page p-2'>

            <div className='md:w-1/2 md:mx-auto'>

                <div
                    className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">{t('login.title')}</h1>
                            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 mb-4">
                                Don't have an account?
                            </p>
                            <Link to='/zenauth/register' className="btn btn-primary btn-small font-medium">
                                Sign up here
                            </Link>
                        </div>

                        <div className="mt-5">


                            <GoogleLogin onSuccess={googleLogin} onError={() => {
                                showAlert(EAlertType.danger, `Sorry but an error occurred: Could not authorize login with Google`)
                            }}/>

                            <div
                                className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:mr-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ml-6 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600">Or
                            </div>

                            <form className='preline-form' onSubmit={(ev) => {
                                submit(ev)
                            }}>

                                <div className="grid gap-y-4">

                                    <div>

                                        <label htmlFor="email" className="block text-sm mb-2 dark:text-white">Email
                                            address<sup>*</sup></label>

                                        <div className="relative">

                                            <input type="email"
                                                   placeholder="Enter email address"
                                                   name={'email'}
                                                   id="email"
                                                   onInput={(event) => {
                                                       const inputElement = event.target as HTMLInputElement;
                                                       handleInput('email', inputElement.value);
                                                   }}
                                                   className="text-input"
                                                   aria-describedby="email-address"/>

                                        </div>
                                        {!validation.email.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">Please enter a valid email address</p>}
                                    </div>

                                    <div>
                                        <div className='flex'>
                                            <label htmlFor="password"
                                                   className="block text-sm mb-2 dark:text-white">Password</label>

                                            <Link to={'/zenauth/forgot-password'} className="text-blue-600 text-sm mb-2 ml-auto font-bold">Forgot password?</Link>

                                        </div>
                                        <div className="relative">

                                            <div>
                                                <div className={'relative mb-2'}>
                                                    <input id="confirmPassword"
                                                           type={showPassword ? 'text' : 'password'}
                                                           className="py-3 ps-4 pe-10 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                                           placeholder="Enter password"
                                                           onInput={(ev) => {
                                                               const inputElement = ev.target as HTMLInputElement;
                                                               handleInput('password', inputElement.value);
                                                           }}
                                                    />
                                                    <button type="button"
                                                            onClick={() => {
                                                                setShowPassword(!showPassword);
                                                            }}
                                                            className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                                        <svg className="shrink-0 size-3.5" width="24" height="24"
                                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                             strokeWidth="2"
                                                             strokeLinecap="round" strokeLinejoin="round">
                                                            <path className="hs-password-active:hidden"
                                                                  d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                            <path className="hs-password-active:hidden"
                                                                  d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                                            <path className="hs-password-active:hidden"
                                                                  d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                                            <line className="hs-password-active:hidden" x1="2" x2="22"
                                                                  y1="2"
                                                                  y2="22"></line>
                                                            <path className="hidden hs-password-active:block"
                                                                  d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                                            <circle className="hidden hs-password-active:block" cx="12"
                                                                    cy="12"
                                                                    r="3"></circle>
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>

                                        </div>

                                        {!validation.password.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="password-error">Please enter your password</p>}

                                    </div>

                                    <button type="submit"
                                            disabled={!formValid}
                                            className="py-3 px-4 inline-flex justify-center items-center gap-2 disabled:cursor-not-allowed rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">

                                        {loading && <span>please wait...</span>}

                                        {!loading && formValid && <span>Login</span>}

                                        {!formValid && <span>Please complete the form correctly</span>}

                                    </button>

                                    <div className="text-xs text-gray-400 mt-4 text-center">
                                        This site is protected by reCAPTCHA and the Google{' '}
                                        <a className="underline" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                                        <a className="underline" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>

                </div>

            </div>


        </div>
    )

}

export default Login
