import React from 'react';


import 'preline';
import './styles/fonts.css';
import './styles/base-styles.less';
import ZenRouter from "./ZenRouter";
import {GoogleOAuthProvider} from "@react-oauth/google";
import Authenticate from "./components/Authenticate";
import GlobalAlert from "./components/GlobalAlert";
import GlobalContextProvider from "./lib/GlobalContextProvider";
import {DndProvider} from "react-dnd";
import {TouchBackend} from "react-dnd-touch-backend";
import {HTML5Backend} from "react-dnd-html5-backend";
import Layout from "./Layout";
import {AnimatePresence} from "framer-motion";
import {BrowserRouter} from "react-router-dom";


const App = () => {

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

    return (
        <div className='app'>
            <BrowserRouter>
                <GoogleOAuthProvider
                    clientId="576794878401-vf6bjpifnb7slmk51i9uet58nbgf88q3.apps.googleusercontent.com">
                    <Authenticate>
                        <GlobalAlert>
                            <GlobalContextProvider>
                                <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
                                    <Layout>
                                        <AnimatePresence mode="wait">
                                            <ZenRouter />
                                        </AnimatePresence>
                                    </Layout>
                                </DndProvider>
                            </GlobalContextProvider>
                        </GlobalAlert>
                    </Authenticate>
                </GoogleOAuthProvider>
            </BrowserRouter>
        </div>
    );

}

export default App;
