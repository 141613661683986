import React, {createContext, ReactNode, useEffect, useRef, useState} from "react";
import {ManageBearerToken} from "../lib/ManageBearerToken";
import {useLocation, useNavigate} from "react-router-dom";
import {ELLMRequestStatus, IUser} from "@yellowmelon/zen-global-types";

const tokenManager = new ManageBearerToken()

const nonAuthenticatedRoutes = [
    '/zenauth/login',
    '/zenauth/register',
    '/zenauth/forgot-password',
    '/zenauth/reset-password',
]

const websocketUrl = process.env.WEBSOCKET_URL

export type IClientUser = Omit<IUser, 'password' | 'forgotPassword'> & {
    llmStatus: ELLMRequestStatus;
    freeTrialExpires: Date | string | null;
}

export interface IAuthContext {
    user: IClientUser | null;
    authStatus: {
        loggedIn: boolean;
        authComplete: boolean;
    };
    doLogin: (() => void) | null;
    doLogout: (() => void) | null;
}

export const AuthContext: React.Context<IAuthContext> = createContext<IAuthContext>(
    {
        user: null,
        authStatus: {
            loggedIn: false,
            authComplete: false,
        },
        doLogin: null,
        doLogout: null
    }
)

interface Props {
    children: ReactNode
}

const Authenticate = ({children}: Props) => {

    const [user, setUser] = useState<IClientUser | null>(null);
    const [authStatus, setAuthStatus] = useState({
        loggedIn: false,
        authComplete: false,
    });


    const location = useLocation();
    const navigate = useNavigate();
    const ws = useRef<WebSocket | null>(null);

    const doLogout = () => {

        setUser(null);
        tokenManager.deleteToken();
        setAuthStatus({loggedIn: false, authComplete: true});
        navigate('/zenauth/login');
    }

    const doLogin = async () => {

        const tokenValid = tokenManager.tokenValid();

        if (!tokenValid) {

            setAuthStatus({loggedIn: false, authComplete: true});
            setUser(null);
            navigate('/zenauth/login');
            return;
        }

        // Always refresh the token - mainly to retrieve subscription status
        await tokenManager.getToken(true);
        const userData: IClientUser | null = tokenManager.getUser();

        setAuthStatus({loggedIn: true, authComplete: true});
        setUser(userData)

        if (!websocketUrl) {
            console.error('No websocket url');
            return;
        }

        /*

        ws.current = new WebSocket(websocketUrl);

        ws.current.onopen = async function () {

            const token = await tokenManager.getToken();

            if(!token){
                return console.error('No websocket token');
            }

            // Send the token after the connection is opened
            ws.current?.send(JSON.stringify({ type: 'authenticate', token }));

            console.log('Connected to WebSocket server');
        };

        ws.current.onmessage = function (event) {

            const message = JSON.parse(event.data);
            if (message.type === 'authenticated') {
                console.log('Authentication successful');
            } else if (message.type === 'unauthorized') {
                console.error('Authentication failed');
                ws.current?.close();
            }
        };

        ws.current.onclose = function (event) {
            console.log('Disconnected from WebSocket server');
        };

        */


    }

    useEffect(() => {

        doLogin().then();

    }, []);

    useEffect(
        () => {

            if (authStatus.authComplete) {

                // If the user is logged in and tries to access a non-authenticated route, redirect to the dashboard
                if (authStatus.loggedIn && nonAuthenticatedRoutes.includes(location.pathname)) {

                    return navigate('/');

                }

                // If the user is not logged in and tries to access an authenticated route, redirect to the login page
                if (!authStatus.loggedIn && !nonAuthenticatedRoutes.includes(location.pathname)) {

                    return navigate('/zenauth/login');

                }


            }

        }, [authStatus, location.pathname]
    )

    // It's possible that the child components are being rendered before the navigation is complete
    // - authcomplete is set to true before the path and login status are evaluated.
    if(!authStatus.authComplete) return null;

    return <AuthContext.Provider value={{user, authStatus, doLogout, doLogin}}>{children}</AuthContext.Provider>;

}

export default Authenticate
