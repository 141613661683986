import React, {useState} from 'react';
import ZenButton from "../../ZenButton";
import {IZenNote, IZenNoteLink} from '@yellowmelon/zen-global-types';


interface Props {
    note: IZenNote;
    linkIndex: number | null;
    closeModal: () => void;
    updateNote: (note: IZenNote) => void;
}

const EditLinkModal = ({note, linkIndex, closeModal, updateNote}: Props) => {

    const [linkDetails, setLinkDetails] = useState<IZenNoteLink>(linkIndex === null ? { url: 'https://', caption: '' } : note.links[linkIndex]);
    const [linkError, setLinkError] = useState<string | null>(null);

    const validateLink = (link: string) => {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!urlPattern.test(link);
    };

    const updateLink = (field: 'url' | 'caption', value: string) => {
        if (field === 'url') {
            if (!value.startsWith('https://')) {
                value = 'https://' + value.replace('http://', '');
            }
            
            if (!value) {
                setLinkError('Link is required');
            } else if (!validateLink(value)) {
                setLinkError('Invalid link');
            } else {
                setLinkError(null);
            }
        }

        setLinkDetails({
            ...linkDetails,
            [field]: value
        });
    };

    const deleteLink = () => {

        if(!confirm('delete link?')) return;

        const updatedLinks = note.links.filter((_, index) => index !== linkIndex);
        updateNote({
            ...note,
            links: updatedLinks
        });
    };


    const saveLink = () => {

        if (linkIndex === null) {

            updateNote({
                ...note,
                links: [...note.links, linkDetails]
            });

        } else {

            const updatedLinks = note.links.map((link, index) => {
                if (index === linkIndex) {
                    return linkDetails;
                }
                return link;
            });
            updateNote({
                ...note,
                links: updatedLinks
            });
        }

            closeModal();
            setLinkDetails({ url: 'https://', caption: '' });

    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
            <div className="bg-white p-4 rounded-md shadow-lg max-w-sm w-full">
                <h4 className="font-medium mb-2">Edit Link</h4>
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                        Link
                        {linkError &&
                            <span className="ml-2 text-xs text-white bg-red-500 rounded px-1">{linkError}</span>}
                    </label>
                    <input
                        type="text"
                        value={linkDetails.url}
                        onChange={(e) => updateLink('url', e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 text-xs py-1 px-2"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Caption</label>
                    <input
                        type="text"
                        value={linkDetails.caption}
                        onChange={(e) => updateLink('caption', e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 text-xs py-1 px-2"
                    />
                </div>
                <div className="flex justify-between">
                    {linkDetails._id && (
                        <ZenButton
                            size='xs'
                            zenType="danger"
                            label="Delete"
                            onClick={deleteLink}
                        />
                    )}
                    <div className="flex">
                        <ZenButton
                            size='xs'
                            className='mr-2'
                            zenType="default"
                            label="Cancel"
                            onClick={closeModal}
                        />
                        <ZenButton
                            size='xs'
                            zenType="primary"
                            label="Save"
                            onClick={saveLink}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditLinkModal;
