import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {Link} from "react-router-dom";

interface Props {
    type?: 'button' | 'submit'
    linkTo?: string
    zenType?: 'primary' | 'info' | 'default' | 'danger' | 'success'
    size?: 'xs' | 'sm' | 'md' | 'lg'
    icon?: IconDefinition | null
    iconClassName?: string
    label: string | React.ReactNode
    className?: string
    id?: string
    onClick?: (ev?: React.FormEvent) => void
    tooltip?: React.ReactNode | null
    disabled?: boolean
}

const styleMap: {[key: string]: string} = {
    primary: 'border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800',
    default: 'font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800',
    danger: 'border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800 ;',
    success: 'border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800',
    info: 'border-transparent font-semibold bg-blue-300 text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800'
}

const sizeMap: {[key:string]: string} = {
    xs: 'text-xs py-[2px] px-3 max-h-[1.5rem]',
    sm: 'py-1 px-3 max-h-[2rem]',
    md: 'py-2 px-3 max-h-[2rem]',
    lg: 'p-5  max-h-[2rem]'
}

const ZenButton = ({ type = 'button', linkTo = '', zenType = 'primary', icon = null, iconClassName='', label, className = '', id, onClick, size = 'md', tooltip = null, disabled = false }: Props) => {
    const buttonContent = (
        <>
            {icon && <span className={`mr-1 ${iconClassName}`}><FontAwesomeIcon icon={icon}/></span>}
            {label}
            {tooltip && <span className={'ml-2'}>{tooltip}</span>}
        </>
    );

    if (linkTo) {
        return (
            <Link
                id={id}
                to={linkTo}
                className={`!no-underline disabled:opacity-50 disabled:cursor-not-allowed inline-flex justify-center items-center rounded-md border h-auto ${styleMap[zenType]} ${sizeMap[size]} ${className}`}>
                {buttonContent}
            </Link>
        )
    }

    const doClick = (ev: React.FormEvent) => {
        if (!onClick) return
        onClick(ev);
    }

    return (
        <button
            id={id}
            disabled={disabled}
            onClick={doClick}
            type={type}
            className={`disabled:opacity-50 disabled:cursor-not-allowed inline-flex justify-center items-center rounded-md border ${styleMap[zenType]} ${sizeMap[size]} ${className}`}>
            {buttonContent}
        </button>
    )
}

export default ZenButton;
