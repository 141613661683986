import React, {DragEvent, useEffect, useRef, useState} from 'react';
import ZenButton from "../../ZenButton";
import {IZenNote, IZenNoteAttachment} from '@yellowmelon/zen-global-types';
import ApiClient from '../../../lib/ApiClient';
import ZenMiniAlert, {EMiniAlertType} from '../../ZenMiniAlert';

const apiClient = new ApiClient();

const MAX_IMAGE_SIZE = 1 * 1024 * 1024; // 1MB max image size
const MAX_DOCUMENT_SIZE = 5 * 1024 * 1024; // 5MB in bytes

interface Props {
    note: IZenNote;
    attachmentIndex: number | null;
    closeModal: () => void;
    updateNote: (note: IZenNote) => void;
}

const EditAttachmentModal = ({note, attachmentIndex, closeModal, updateNote}: Props) => {

    const [attachmentDetails, setAttachmentDetails] = useState<IZenNoteAttachment>(
        attachmentIndex === null ?
        { url: '', caption: '', type: 'image' } :
        note.attachments[attachmentIndex]
    );

    const [attachmentError, setAttachmentError] = useState<string | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(
        attachmentIndex !== null && note.attachments[attachmentIndex].type === 'image'
            ? note.attachments[attachmentIndex].url
            : null
    );
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            await handleFile(files[0]);
        }
    };

    const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            await handleFile(files[0]);
        }
    };

    const handleFile = async (file: File) => {

        setAttachmentError(null);

        // Check for allowed file types
        const isImage = file.type.startsWith('image/');
        const isAllowedDocument = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].includes(file.type);

        if (!isImage && !isAllowedDocument) {
            setAttachmentError('Unsupported file type. Please upload an image, PDF, DOC, DOCX, or TXT file.');
            return;
        }

        const fileType = isImage ? 'image' : 'document';
        const maxSize = fileType === 'image' ? MAX_IMAGE_SIZE : MAX_DOCUMENT_SIZE;

        if (file.size > maxSize) {
            const sizeInMB = maxSize / (1024 * 1024);
            setAttachmentError(`File size exceeds ${sizeInMB}MB limit`);
            return;
        }

        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }

        setSelectedFile(file);

        if (fileType === 'image') {
            const preview = URL.createObjectURL(file);
            console.log('Created preview URL:', preview);
            setPreviewUrl(preview);
        } else {
            setPreviewUrl(null);
        }

        setAttachmentDetails({
            ...attachmentDetails,
            filename: file.name,
            type: fileType,
            mimeType: file.type,
            size: file.size,
            caption: file.name
        });
    };

    useEffect(() => {
        return () => {
            if (previewUrl && !previewUrl.startsWith('http')) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);


    const deleteAttachment = () => {
        if(!confirm('Delete attachment?')) return;

        const updatedAttachments = note.attachments.filter((_, index) => index !== attachmentIndex);
        updateNote({
            ...note,
            attachments: updatedAttachments
        });
        closeModal();
    };

    const saveAttachment = async () => {
        try {

            // If we have a file to upload
            if (attachmentDetails.filename && selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('caption', attachmentDetails.caption);
                formData.append('type', attachmentDetails.type);

                const updatedNote = await apiClient.post<IZenNote>(`api/v1/notes/${note._id}/attachment`, {
                    payload: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                updateNote(updatedNote);
                closeModal();
                return;
            }

            // If we're just updating existing attachment details (no new file)
            if (attachmentIndex === null) {

                updateNote({
                    ...note,
                    attachments: [...note.attachments, attachmentDetails]
                });

            } else {

                const updatedAttachments = note.attachments.map((attachment, index) => {
                    if (index === attachmentIndex) {
                        return attachmentDetails;
                    }

                    return attachment;

                });

                updateNote({
                    ...note,
                    attachments: updatedAttachments
                });

            }

            closeModal();
            setAttachmentDetails({ url: '', caption: '', type: 'image' });

        } catch (error) {
            console.error('Error saving attachment:', error);
            setAttachmentError('Failed to save attachment');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
            <div className="bg-white p-4 rounded-md shadow-lg max-w-sm w-full">
                <h4 className="font-medium mb-2">Edit Attachment</h4>

                { !previewUrl &&
                    <div
                        className={`mb-4 border-2 border-dashed rounded-lg p-4 text-center cursor-pointer
                        ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
                        hover:border-blue-500 hover:bg-blue-50 transition-colors`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={handleFileInput}
                            accept="image/*,.pdf,.doc,.docx,.txt"
                        />
                        <div className="text-sm text-gray-600">
                            <p>Drag and drop a file here, or click to select</p>
                            {attachmentDetails.filename && (
                                <p className="mt-2 text-blue-600">Selected: {attachmentDetails.filename}</p>
                            )}
                        </div>
                    </div>
                }
                {attachmentError && (
                    <div className={'flex justify-center'}>
                        <ZenMiniAlert
                            type={EMiniAlertType.danger}
                            message={attachmentError}
                        />
                    </div>
                )}

                {/* Image Preview */}
                {previewUrl && attachmentDetails.type === 'image' && (
                    <div className="mb-4 relative">
                        <img
                            src={previewUrl}
                            alt="Preview"
                            className="max-h-48 w-auto mx-auto rounded-lg shadow-sm object-contain"
                            onError={(e) => console.error('Image failed to load:', e)}
                        />
                    </div>
                )}

                {attachmentDetails.filename && attachmentDetails.type === 'document' && (
                    <div className="mb-4 text-center text-gray-500">
                        <svg
                            className="w-12 h-12 mx-auto"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                            />
                        </svg>
                        <p className="text-sm mt-2">{attachmentDetails.filename}</p>
                    </div>
                )}

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Caption</label>
                    <input
                        type="text"
                        value={attachmentDetails.caption}
                        onChange={(e) => setAttachmentDetails({
                            ...attachmentDetails,
                            caption: e.target.value
                        })}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 text-xs py-1 px-2"
                    />
                </div>

                <div className="flex justify-between">
                    {attachmentDetails._id && (
                        <ZenButton
                            size='xs'
                            zenType="danger"
                            label="Delete"
                            onClick={deleteAttachment}
                        />
                    )}
                    <div className="flex">
                        <ZenButton
                            size='xs'
                            className='mr-2'
                            zenType="default"
                            label="Cancel"
                            onClick={closeModal}
                        />
                        <ZenButton
                            size='xs'
                            zenType="primary"
                            label="Save"
                            onClick={saveAttachment}
                            disabled={!attachmentDetails.filename && attachmentIndex === null}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAttachmentModal;
