import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faPaperclip, faTags} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {IZenNote} from "@yellowmelon/zen-global-types";
import DOMPurify from "dompurify";

interface Props {
    note: IZenNote
}
const ZenNoteSummary = ({note}: Props) => {

    const i = 0;

    return (
        <div className={`flex flex-col p-3 bg-white rounded-xl shadow hover:shadow-lg transition-all duration-300 cursor-pointer `}
        >
            <h3 className="text-base font-semibold text-gray-800 mb-2">
                {note.title ||
                    (note.note && note.note.length > 0 ? DOMPurify.sanitize(note.note).replace(/<[^>]*>/g, '').slice(0, 50) :
                        note.links.length > 0 ? note.links[0].caption :
                            "Untitled note")}
            </h3>
            <div className="flex gap-3 justify-end text-xs">
                <span title={`${note.links.length} links`} className="text-gray-400">
                    <FontAwesomeIcon icon={faLink} className="mr-1"/>
                    {note.links.length}
                </span>
                <span title={`${note.attachments.length} attachments`} className="text-gray-400">
                    <FontAwesomeIcon icon={faPaperclip} className="mr-1"/>
                    {note.attachments.length}
                </span>
                <span title={`${note.tags?.length || 0} tags`} className="text-gray-400">
                    <FontAwesomeIcon icon={faTags} className="mr-1"/>
                    {note.tags?.length || 0}
                </span>
            </div>
        </div>
    )

}

export default ZenNoteSummary;
