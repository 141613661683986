import React from "react";

interface IPageTitle{
    title: string, imgClass: string
}

interface IPageTitleMap {
    [index: string]: IPageTitle
}

const titleMap: IPageTitleMap = {
    '/': {
        title: 'Kanban',
        imgClass: 'background-tropical'
    },
    '/zenauth/register': {
        title: 'Register',
        imgClass: 'background-tropical'
    },
    '/zenauth/login': {
        title: 'Login',
        imgClass: 'background-tropical'
    },
    '/account': {
        title: 'Your Account',
        imgClass: 'background-tropical'
    },
    '/kanban': {
        title: 'Kanban',
        imgClass: 'background-tropical'
    },
    '/notes': {
        title: 'Notes',
        imgClass: 'background-tropical'
    },
    '/subscription': {
        title: 'Subscribe',
        imgClass: 'background-tropical'
    },
    '/goals/overview': {
        title: 'Goal Overview',
        imgClass: 'background-tropical'
    },
    '/top-goals/analyse/suggestions': {
        title: 'Goal Suggestions',
        imgClass: 'background-tropical'
    },
    '/top-goals/analyse/tasklist': {
        title: 'Task List',
        imgClass: 'background-tropical'
    },
    '/goals': {
        title: 'Top Level Goals',
        imgClass: 'background-tropical'
    },
    '/my-plan': {
        title: 'My Plan',
        imgClass: 'background-tropical'
    },
    'not-found': {
        title: 'Page not found',
        imgClass: 'background-tropical'
    }

}

const findLongestPartialMatch = (inputValue: string, titleObj : IPageTitleMap) : IPageTitle | null => {

    const keys = Object.keys(titleObj);
    let longestMatch = null;

    for (const key of keys) {
        if (inputValue.startsWith(key) && (longestMatch === null || key.length > longestMatch.length)) {
            longestMatch = key;
        }
    }

    if(!longestMatch) return null;

    return titleObj[longestMatch];
}

const PageTitle = ({path}: { path: string }) => {

    const pageTitle = findLongestPartialMatch(path, titleMap)

    const titleObject = pageTitle ? pageTitle : titleMap['not-found']

    return (
        <section
            className={`title-background ${titleObject.imgClass} bg-cover bg-center h-[15vh] md:h-[30vh] overflow-hidden md:mb-8`}>

            <h1
                className='mb-4 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl '>{titleObject.title}</h1>

        </section>
    )


}

export default PageTitle
