import React, {useContext, useEffect, useState} from 'react';
import ApiClient from "../../lib/ApiClient";
import {IZenNote} from "@yellowmelon/zen-global-types";
import ZenButton from "../../components/ZenButton";
import {EAlertType, GlobalAlertContext} from '../../components/GlobalAlert';
import ZenNoteSummary from "./NoteSummary";
import ZenModal from "../../components/ZenModal";
import ZenNote from "../../components/ZenNote";
import {debounce} from "lodash";
import ZenMiniAlert, {EMiniAlertType} from "../../components/ZenMiniAlert";

const apiClient = new ApiClient();

const defaultNote: IZenNote = {
    title: '',
    index: 0,
    note: '',
    links: [],
    attachments: [],
    tags: []
};

interface ICurrentNote {
    note: IZenNote;
    isDirty: boolean;
    saved: boolean;
}


const Notes = () => {

    const {showAlert} = useContext(GlobalAlertContext);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [notes, setNotes] = useState<IZenNote[]>([]);
    const [currentNote, setCurrentNote] = useState<ICurrentNote>(
        {
            note: defaultNote,
            isDirty: false,
            saved: false
        }
    );
    const [showModal, setShowModal] = useState<boolean>(false);

    const fetchNotes = async () => {
        try {
            const response = await apiClient.get<IZenNote[]>('api/v1/notes');
            setNotes(response);
        } catch (error) {
            console.error("Failed to fetch notes:", error);
        }
    };

    useEffect(() => {

        fetchNotes().then();

    }, []);


    const debouncedSave = debounce(async (note: IZenNote) => {

        setCurrentNote(prev => ({...prev, saved: false}));

        try {

            // @ts-ignore
            const grecaptcha = window.grecaptcha;

            // Add reCAPTCHA token generation
            const googleToken = await new Promise<string>(
                (resolve, reject) => {
                    grecaptcha.ready(function() {
                        grecaptcha.execute(
                            process.env.GOOGLE_RECAPTCHA_SITE_KEY,
                            {action: 'submit'}).then(
                            (token: string) => {
                                resolve(token);
                            });
                    });
                }
            );


            await apiClient.put<IZenNote>(`api/v1/notes/${note._id}`, {payload: {note, googleToken}});

            setCurrentNote(prev => ({
                ...prev,
                isDirty: false,
                saved: true
            }));


        } catch (error: any) {

            console.error("Failed to update note:", error);
            showAlert(EAlertType.danger, `Failed to update note: ${error.message}`);

        }

    }, 1000)


    const doUpdate = (note: IZenNote) => {

        setCurrentNote(prev => ({
            ...prev,
            note: note,
            isDirty: true
        }));

        if (!note._id) return; // Only debounce save for existing notes
        debouncedSave(note);
    }

    const addNote = () => {

        setCurrentNote({note: defaultNote, isDirty: false, saved: false});
        setIsEditing(true);
        setShowModal(true);
    };

    // Save and delete functionality needs to be outside the modal since the buttons are part of the modal - not the note.
    const saveNote = async () => {

        try {

            setCurrentNote(prev => ({...prev, saved: false}));

            // @ts-ignore
            const grecaptcha = window.grecaptcha;

            // Add reCAPTCHA token generation
            const googleToken = await new Promise<string>(
                (resolve, reject) => {
                    grecaptcha.ready(function() {
                        grecaptcha.execute(
                            process.env.GOOGLE_RECAPTCHA_SITE_KEY,
                            {action: 'submit'}).then(
                            (token: string) => {
                                resolve(token);
                            });
                    });
                }
            );

            const savedNote = await apiClient.post<IZenNote>('api/v1/notes', {payload: {note: currentNote.note, googleToken}});

            setCurrentNote(prev => ({
                ...prev,
                note: savedNote,
                saved: true,
                isDirty: false
            }));


        } catch (error: any) {
            console.error("Failed to save note:", error);
            showAlert(EAlertType.danger, `Failed to create note: ${error.message}`);
        }
    };


    const deleteNote = async () => {

        if (!confirm('Delete note?')) return;

        const noteId = currentNote.note._id;

        if (!noteId) {
            console.error("Note ID is missing");
            return;
        }

        try {

            await apiClient.delete(`api/v1/notes/${noteId}`);
            setNotes(notes.filter(note => note._id !== noteId));
            showAlert(EAlertType.success, 'Note deleted successfully');
            setShowModal(false);

        } catch (error: any) {

            console.error("Failed to delete note:", error);
            showAlert(EAlertType.danger, `Failed to delete note: ${error.message}`);

        }
    };


    return (
        <>
            <div className='page-content home-page p-2 flex flex-col'>
                <h2 className={'text-center text-2xl font-bold mb-8'}>
                    Those random thoughts that you should really copy down? This is the place!
                </h2>

                <div className="flex justify-center mb-4">
                    <ZenButton label={'Add New Note'} onClick={addNote}/>
                </div>

                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {notes.map(note =>
                        (<div
                            key={note._id?.toString()}
                            onClick={() => {
                                setCurrentNote({...currentNote, note});
                                setShowModal(true);
                            }}>
                            <ZenNoteSummary
                                key={note._id?.toString()}
                                note={note}/>
                        </div>))}
                </div>


            </div>

            <ZenModal
                title={currentNote.note?.title}
                showModal={showModal}
                closeModal={
                    async () => {

                        if (!currentNote.saved &&
                            currentNote.isDirty &&
                            !confirm('You have unsaved changes. Are you sure you want to close?')) {
                            return;
                        }

                        setCurrentNote({...currentNote, isDirty: false});
                        setIsEditing(false);

                        await fetchNotes();
                        setShowModal(false)
                    }
                }
                buttons={
                    <div className="flex justify-between items-center w-full">

                        {currentNote.note._id && (
                            <ZenButton
                                zenType="danger"
                                label="Delete Note"
                                onClick={deleteNote}
                            />
                        )}

                        <div className={'ml-auto'}>

                            {
                                currentNote.saved && !isEditing &&
                                <ZenMiniAlert message={'Note saved'} type={EMiniAlertType.success}/>
                            }

                            {
                                currentNote.isDirty && !currentNote.saved &&
                                <ZenMiniAlert message={'Note not saved'} type={EMiniAlertType.danger}/>
                            }

                            {
                                currentNote.note._id && isEditing &&
                                <ZenButton
                                    className="ml-auto mr-2"
                                    zenType="primary"
                                    label="Finish Editing"
                                    onClick={() => {
                                        setIsEditing(false);
                                    }}
                                />

                            }

                            {
                                currentNote.note._id && !isEditing &&
                                <ZenButton
                                    className="ml-auto mr-2"
                                    zenType="primary"
                                    label="Edit Note"
                                    onClick={() => {
                                        setIsEditing(true);
                                    }}
                                />

                            }

                            {
                                isEditing && !currentNote.note._id && <ZenButton
                                    className="ml-auto mr-2"
                                    zenType="primary"
                                    label="Save Note"
                                    onClick={() => {
                                        saveNote().then();
                                    }
                                    }

                                />
                            }


                        </div>
                    </div>
                }>

                <ZenNote
                    note={currentNote.note}
                    isEditing={isEditing}
                    onUpdate={(note) => {
                        doUpdate(note);
                    }}/>

            </ZenModal>
        </>
    )
}

export default Notes;
