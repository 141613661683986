import React, { useEffect, useRef } from 'react';
import Viewer from 'viewerjs';

interface FileViewerModalProps {
    file: {
        url: string;
        caption: string;
        mimeType?: string;
    };
    currentIndex: number;
    attachments: Array<{
        url: string;
        caption: string;
        mimeType?: string;
    }>;
    onClose: () => void;
    onNavigate: (index: number) => void;
}

const FileViewerModal: React.FC<FileViewerModalProps> = ({ 
    file, 
    currentIndex, 
    attachments, 
    onClose, 
    onNavigate 
}) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const viewerRef = useRef<Viewer | null>(null);

    useEffect(() => {
        if (imageRef.current && file.mimeType?.startsWith('image/')) {
            viewerRef.current = new Viewer(imageRef.current, {
                inline: false,
                viewed() {
                    viewerRef.current?.zoomTo(1);
                },
            });
            return () => {
                viewerRef.current?.destroy();
            };
        }
    }, [file]);

    const renderContent = () => {
        if (file.mimeType?.startsWith('image/')) {
            return (
                <img
                    ref={imageRef}
                    src={file.url}
                    alt={file.caption}
                    className="max-w-full h-auto"
                />
            );
        } else if (file.mimeType === 'application/pdf') {
            return (
                <iframe
                    src={file.url}
                    className="w-full h-[calc(90vh-8rem)]"
                    title={file.caption}
                />
            );
        } else {
            return (
                <div className="text-center py-8">
                    <p className="mb-4">This file type cannot be previewed.</p>
                    <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                    >
                        Download File
                    </a>
                </div>
            );
        }
    };

    const handlePrevious = () => {
        const newIndex = (currentIndex - 1 + attachments.length) % attachments.length;
        onNavigate(newIndex);
    };

    const handleNext = () => {
        const newIndex = (currentIndex + 1) % attachments.length;
        onNavigate(newIndex);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-hidden flex flex-col">
                <div className="flex justify-between items-center p-4 border-b">
                    <h3 className="text-lg font-medium">{file.caption}</h3>
                    <div className="flex items-center gap-4">
                        <span className="text-sm text-gray-500">
                            {currentIndex + 1} / {attachments.length}
                        </span>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <span className="sr-only">Close</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="p-4 flex-1 overflow-auto relative">
                    {renderContent()}
                    
                    {attachments.length > 1 && (
                        <>
                            <button
                                onClick={handlePrevious}
                                className="absolute left-4 top-1/2 -translate-y-1/2 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <button
                                onClick={handleNext}
                                className="absolute right-4 top-1/2 -translate-y-1/2 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileViewerModal;
