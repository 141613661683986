(function (factory) {
    if (typeof module === "object" && typeof module.exports === "object") {
        var v = factory(require, exports);
        if (v !== undefined) module.exports = v;
    }
    else if (typeof define === "function" && define.amd) {
        define(["require", "exports"], factory);
    }
})(function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.EZenChatMessageRoles = exports.ENotificationType = exports.ENotificationFrequency = exports.EZenObjectiveSuggestionStatus = exports.ETaskPriority = exports.EActiveStatus = exports.ETaskStatus = exports.ELoginError = exports.ELLMRequestStatus = exports.EStripeSubscriptionBillingCycle = exports.EStripeSubscriptionType = exports.EStripeSubscriptionStatus = void 0;
    var EStripeSubscriptionStatus;
    (function (EStripeSubscriptionStatus) {
        EStripeSubscriptionStatus["ACTIVE"] = "ACTIVE";
        EStripeSubscriptionStatus["INACTIVE"] = "INACTIVE";
        EStripeSubscriptionStatus["PENDING_CANCEL"] = "PENDING_CANCEL";
    })(EStripeSubscriptionStatus || (exports.EStripeSubscriptionStatus = EStripeSubscriptionStatus = {}));
    // Initially there is only one subscription type but this could change
    var EStripeSubscriptionType;
    (function (EStripeSubscriptionType) {
        EStripeSubscriptionType["BASIC"] = "BASIC";
        EStripeSubscriptionType["PREMIUM"] = "PREMIUM";
    })(EStripeSubscriptionType || (exports.EStripeSubscriptionType = EStripeSubscriptionType = {}));
    var EStripeSubscriptionBillingCycle;
    (function (EStripeSubscriptionBillingCycle) {
        EStripeSubscriptionBillingCycle["MONTHLY"] = "MONTHLY";
        EStripeSubscriptionBillingCycle["YEARLY"] = "YEARLY";
    })(EStripeSubscriptionBillingCycle || (exports.EStripeSubscriptionBillingCycle = EStripeSubscriptionBillingCycle = {}));
    var ELLMRequestStatus;
    (function (ELLMRequestStatus) {
        ELLMRequestStatus["LIMIT_REACHED"] = "LIMIT_REACHED";
        ELLMRequestStatus["LIMIT_CLOSE"] = "LIMIT_CLOSE";
        ELLMRequestStatus["LIMIT_OK"] = "LIMIT_OK";
    })(ELLMRequestStatus || (exports.ELLMRequestStatus = ELLMRequestStatus = {}));
    // *** Important. 'export const enum' will compile but fail at runtime ***
    var ELoginError;
    (function (ELoginError) {
        ELoginError["USER_NOT_FOUND"] = "USER_NOT_FOUND";
        ELoginError["PASSWORD_INCORRECT"] = "PASSWORD_INCORRECT";
        ELoginError["USER_ALREADY_REGISTERED"] = "USER_ALREADY_REGISTERED";
        ELoginError["SYSTEM_ERROR"] = "SYSTEM_ERROR";
    })(ELoginError || (exports.ELoginError = ELoginError = {}));
    var ETaskStatus;
    (function (ETaskStatus) {
        ETaskStatus["inbox"] = "inbox";
        ETaskStatus["thisWeek"] = "thisWeek";
        ETaskStatus["today"] = "today";
        ETaskStatus["done"] = "done";
    })(ETaskStatus || (exports.ETaskStatus = ETaskStatus = {}));
    var EActiveStatus;
    (function (EActiveStatus) {
        EActiveStatus["active"] = "ACTIVE";
        EActiveStatus["deleted"] = "DELETED"; // Archived
    })(EActiveStatus || (exports.EActiveStatus = EActiveStatus = {}));
    var ETaskPriority;
    (function (ETaskPriority) {
        ETaskPriority["high"] = "HIGH";
        ETaskPriority["normal"] = "NORMAL";
    })(ETaskPriority || (exports.ETaskPriority = ETaskPriority = {}));
    var EZenObjectiveSuggestionStatus;
    (function (EZenObjectiveSuggestionStatus) {
        EZenObjectiveSuggestionStatus["active"] = "ACTIVE";
        EZenObjectiveSuggestionStatus["deleted"] = "DELETED";
    })(EZenObjectiveSuggestionStatus || (exports.EZenObjectiveSuggestionStatus = EZenObjectiveSuggestionStatus = {}));
    // Notifications
    // There will be a notifications section in the UI for notification configuration in the future. For now everyone gets a
    // status update at 6am on weekdays
    var ENotificationFrequency;
    (function (ENotificationFrequency) {
        ENotificationFrequency[ENotificationFrequency["daily"] = 0] = "daily";
        ENotificationFrequency[ENotificationFrequency["weekdays"] = 1] = "weekdays";
        ENotificationFrequency[ENotificationFrequency["weekly"] = 2] = "weekly";
    })(ENotificationFrequency || (exports.ENotificationFrequency = ENotificationFrequency = {}));
    var ENotificationType;
    (function (ENotificationType) {
        ENotificationType["statusUpdate"] = "STATUS_UPDATE";
    })(ENotificationType || (exports.ENotificationType = ENotificationType = {}));
    var EZenChatMessageRoles;
    (function (EZenChatMessageRoles) {
        EZenChatMessageRoles["system"] = "system";
        EZenChatMessageRoles["user"] = "user";
        EZenChatMessageRoles["assistant"] = "assistant";
    })(EZenChatMessageRoles || (exports.EZenChatMessageRoles = EZenChatMessageRoles = {}));
});
