import React from 'react'
import {IZenObjectiveResourceModel} from "@yellowmelon/zen-global-types";


const GoalResourceCard = ({resource}: { resource: IZenObjectiveResourceModel })=>{

    return (
        <div className='bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 mb-2 p-4 pb-3 mx-2'>
            <h5 className='font-bold'>{resource.resource}</h5>
            <p>{resource.description}</p>
            {
                !!resource.link && (
                    <a className='' target='_blank' href={resource.link}>click here to visit</a>
                )
            }
        </div>
    );
}

export default GoalResourceCard
